import React, { Component }  from 'react';
import axios from "axios";
import './inventory.css';
import MySettings from './mySettings.js';
import AppSettings from './appSettings.js';
import queryString from 'query-string';
import GenericSetings from './genericSettings.js';

import UserSettings from './userSettings.js';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab'
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import LinkedWebAccounts from './webAccounts.js'
import {withRouter} from './withRouter';
import {addToQueryString,clearQueryString} from '../utils/QueryStringModder'
import { ReleaseNotes } from './ReleaseNotes.js';
let endpoint = "";

 class UserSettingsPage extends Component{
   constructor(props) {
    super(props);
    this.state={
        errorMessage:"",
        showAlert:false,
        isError:false,
        users:[],
        usersSuccess:false,
        accounts:[],
        webAccounts:[],
        queryString:"",
        activeTab:"ssettings",
        versions:{}
    }
    this.updateAlert = this.updateAlert.bind(this)
    this.onPushToHistory = this.onPushToHistory.bind(this)
   
   

  }    

      
  componentDidMount() { 
    this.getUsers()
    this.getAccounts()
    this.getWebAccounts()
    this.getVersions()
    this.setState({queryString:queryString .parse(this.props.location.search)})

    if(queryString .parse(this.props.location.search).page && queryString .parse(this.props.location.search).page==="web-accounts"){
      this.setState({ activeTab:"secondb"})
    }
    if(queryString .parse(this.props.location.search).page && queryString .parse(this.props.location.search).page==="about"){
      this.setState({ activeTab:"aboutapp"})
    }
    if(queryString .parse(this.props.location.search).state && queryString .parse(this.props.location.search).state!="undefined"){
      this.setState({ activeTab:"secondb"})
      var string  = this.props.location.search
      if(string.includes("code") && !string.includes('spapi_oauth_code')){ //ebay
        var first = string.split("code")[0]
        var second = string.split("code")[1]
        first = first.replace(/%3D/g, "=").replace(/%3B/g, "&")
        this.setState({queryString:queryString .parse(first+"code"+second)})
      }else{ //amzn
        var first = string.split("state")[0]
        var second = string.split("state")[1]
        second = second.replace(/%3D/g, "=").replace(/%3B/g, "&")
        this.setState({queryString:queryString .parse(first+"state"+second)})
      }
      


    }
  }

  updateAlert = (data)=>{
    this.setState({errorMessage:data.errorMessage, 
                    showAlert:data.showAlert,
                    isError:data.isError})

  }
     

  getAccounts = () =>{
    let currentComponent=this;
  
    axios.get(endpoint + "/api/get-account-nums",
    {
     headers: {
       Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
     }
    }).then(res => {
      if (res.data) {
        currentComponent.setState({
          accounts: res.data,
        });
      } 
    }).catch(error => { 
    // handle error
      if (error.response){
        if(error.response.status===401){
          this.props.setLogcallback("","")
            this.props.logincallback("Error! You have been logged out. Please log back in")
            currentComponent.props.navigate('/index.html')
        }
    } 
   }); 
  };    

  getWebAccounts = () =>{
    let currentComponent=this;
  
    axios.get(endpoint + "/api/get-web-accounts",
    {
     headers: {
       Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
     }
    }).then(res => {
      if (res.data) {
        currentComponent.setState({
          webAccounts: res.data,
        });
      } 
    }).catch(error => { 
    // handle error
      if (error.response){
        if(error.response.status===401){
          this.props.setLogcallback("","")
            this.props.logincallback("Error! You have been logged out. Please log back in")
            currentComponent.props.navigate('/index.html')
        }
    } 
   }); 
  }; 

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.username != this.props.username){
      this.getUsers()
      this.getWebAccounts()
    }
  }

  onPushToHistory(key,content){

    
     var tmp={}
     tmp[key]=content
     this.props.setSearchParams(tmp)

  }

  getUsers = () =>{
    var component = this;
      
        var payload={username:this.props.username }
        
          axios.post(endpoint + '/api/get-users',
          payload,{
           headers: {
             Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
           }
          })
        .then(function (response) {
          component.setState({users:response.data, usersSuccess:true})
          
          //console.log(response.headers);
        })
        .catch(function (error) {
            
            
        });
  }

  getVersions = () =>{
    var component = this;
      
        
          axios.get(endpoint + '/api/get-versions',
          {
           headers: {
             Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
           }
          })
        .then(function (response) {
          component.setState({versions:response.data})
          
          //console.log(response.headers);
        })
        .catch(function (error) {
            
            
        });
  }

  render(){

    function AlertOrderStatus(props) {
        
        if(props.isError){
            if(props.show){
             window.setTimeout(()=>{
              props.hideAlert()
              },7000)
            }
            return (
              <Alert show={props.show} variant="danger" onClose={props.hideAlert} dismissible>
                
                {props.mesage}
                
              </Alert>
            );
        }else{
            if(props.show){
             window.setTimeout(()=>{
              props.hideAlert()
              },5000)
            }
             return (
              <Alert show={props.show} variant="success" onClose={props.hideAlert}  dismissible>
                {props.mesage}
              </Alert>
            );
        }
      
    }


      return(
      <div>
      <div className="wrapper fullPageWrapper">
      <div className="customtable widetable">

            <AlertOrderStatus show={this.state.showAlert} isError={this.state.isError} mesage={this.state.errorMessage} hideAlert={()=>this.setState({showAlert:false})} />
            <div className="headerBox">
          <h1 className="left-align">Settings</h1>
          </div>
          <div className="  grayheader tablerow">
          <Tab.Container id="left-tabs-example" activeKey={this.state.activeTab}onSelect={(k) => this.setState({activeTab:k})}>
            <Row>
              
                <Col sm={3}>
                <div  className="customtable widetable">
                <div style={{paddingLeft:0, paddingRight:0}} className="headerBox">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item> 
                   <Nav.Link eventKey="ssettings">Store Settings</Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                    <Nav.Link eventKey="first">My Account</Nav.Link>
                    </Nav.Item>
                    {this.state.usersSuccess &&<Nav.Item> 
                    <Nav.Link eventKey="second">User Accounts</Nav.Link>
                    </Nav.Item> }
                    {this.state.usersSuccess &&<Nav.Item> 
                    <Nav.Link eventKey="secondb">Linked Web Accounts</Nav.Link>
                    </Nav.Item> }
                    <Nav.Item> 
                    <Nav.Link eventKey="third">Vendor Settings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item> 
                   
                    <Nav.Link eventKey="aboutapp">About Application</Nav.Link>
                    </Nav.Item>
                </Nav>
                </div>
                </div>
                </Col>
                <Col sm={9}>
                <div style={{height:"100%"}} className="customtable widetable">
                <div className="   tablerow">
                <Tab.Content>
                  <Tab.Pane eventKey="ssettings">
                        <GenericSetings token = {this.props.token}  updateAlert={this.updateAlert}/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="first">
                        <MySettings token = {this.props.token} username = {this.props.username} updateAlert={this.updateAlert}/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <UserSettings  token = {this.props.token} username = {this.props.username} 
                                    updateAlert={this.updateAlert} users={this.state.users} 
                                    reloadUsers = {this.getUsers}/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="secondb">
                      <LinkedWebAccounts  token = {this.props.token} accounts ={this.state.webAccounts}
                                    updateAlert={this.updateAlert}   onPushToHistory={this.onPushToHistory} qString = {this.state.queryString} reloadAccounts = {this.getWebAccounts}
                                    />
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      <AppSettings token = {this.props.token} 
                                    updateAlert={this.updateAlert}
                                    accounts ={this.state.accounts}
                                    reloadAccounts={this.getAccounts} />
                    </Tab.Pane>

                    <Tab.Pane eventKey="aboutapp">
                    {this.state.versions&&
                      <div>
                      <div>
                        <h2 style={{fontSize:"1.75em"}}>About This Application</h2>
                        <hr></hr>
                       
                        <p>Lynx 3.1.1_6</p>
                        <p>GServe {this.state.versions.server_version}</p>
                        <p>Link {this.state.versions.link_version}</p>
                        <p>Copyright 2020-24, Justin Barish</p>
                        <p>All Rights Reserved</p>
                      </div>
                      <hr></hr>
                      <ReleaseNotes/>
                      </div>}
                    </Tab.Pane>
                </Tab.Content>
                </div>
                </div>
                </Col>
            </Row>
         </Tab.Container>
         </div>
         </div>



      </div>
      {/*<p style={{marginTop:"-20px"}}>Web UI Build 1.2.3_0 -- Server Build 1.1.6b.1 -- Link Build 1.0.8b.1</p>*/}
      </div>


              
           
  )
  }
 }

  export default withRouter(UserSettingsPage);
  
  /*
  
  */